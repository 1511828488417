import React, {useState} from 'react';

import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import * as Config from '../assets/config';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import * as Api from '../API/API.js';
import { COLORS } from '../assets/colors';


function ContactModal(props) {


  const [emailAddress, setEmailAddress] = useState("");
  const [text, setText] = useState("");

  const clearData = () => {
    setEmailAddress("");
    setText("");

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();


    let data = {
      "sourceemail" : Config.mail_address,
      "email": emailAddress,
      "text" : text
    };

    handleSendMessage(data);
    handleClose();
    
  };


  const handleClose = () => {
    clearData();
    props.handleClose();
  }


  const handleSendMessage = (data) => {
    
       Api.sendMessage(data).then(val => {
            console.log(val);
           
        }).catch(error =>{
            console.log("sendMessage - ERROR");
            alert("Server Error");
        });
}

  return (
    <Modal 
    show={props.show} 
    onHide={() => handleClose()}
    size="lg"
    centered>
        <Modal.Header closeButton>
         
          
          <Modal.Title className='justify-content-center align-items-center'> <Row><Col xs={6}>
          </Col>
          </Row> </Modal.Title>
          
          
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleSubmit}>
           {/*<Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>{Config.contact_modal_labels.email_field_label[props.language]}</Form.Label>
            <Form.Control type="email" placeholder={Config.contact_modal_labels.email_field_placeholder[props.language]} onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} />
  </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Message</Form.Label>
            <Form.Control required as="textarea" rows={5} placeholder={Config.contact_modal_labels.message_field_placeholder[props.language]} onChange={(e) => setText(e.target.value)} value={text} />
          </Form.Group>
          <Row className="justify-content-center text-center">
            <Col >
                <Button className="contactsendbutton" type="submit">{Config.contact_modal_labels.send_button[props.language]}</Button>
            </Col>
            </Row>
            <Row className="mt-2 justify-content-center text-center">
            <Col >
            {Config.contact_modal_labels.send_email_indication[props.language]} <p><a id="sendmessagelink" href={"mailto:"+ Config.mail_address} style={styles.emailurl} to='#'
                >{Config.mail_address}</a></p>

            </Col>
            </Row>
        </Form>
        </Modal.Body>
      </Modal>
  );
}


const styles = {
  emailurl:{
    cursor: 'pointer',
    color: COLORS.green_sophia 
  }
 
}


export default ContactModal;

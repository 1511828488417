
const SERVER_URL = "https://bariatric-weight-trajectory-prediction.univ-lille.fr";
//const SERVER_URL = "http://bariascool-dev.univ-lille.fr";
//const SERVER_URL = "http://0.0.0.0:5000";
//const SERVER_URL = "http://192.168.1.13:5000"

const predict = async (data) => {

    var url = SERVER_URL + "/predict?weight="+data.weight+"&height="+data.height+"&interventionType="+data.interventionType+"&age="+data.age+"&type2diabetes="+data.type2diabetes+"&yearsWithDiabetes="+data.yearsWithDiabetes+"&isSmoker="+data.isSmoker+"&system="+data.system;


    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
    })
    .then((response) => response.json())
    .then((json) => {
        console.log("API : predict - OK");
        return json;
    })
    .catch((error) => {
        console.log("API : predict - ERROR")
        error.text()
    });


}


const getPredictionsNumber = async (data) => {


    var url = SERVER_URL + "/get_predictions_number";


    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
    })
    .then((response) => response.json())
    .then((json) => {
        console.log("API : get_predictions_number - OK");
        return json;
    })
    .catch((error) => {
        console.log("API : get_predictions_number - ERROR")
        error.text()
    });


}


const sendMessage = async (data) => {


    var url = SERVER_URL + "/send_message";


    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
    .then((json) => {
        console.log("API : send_message - OK");
        return json;
    })
    .catch((error) => {
        console.log("API : send_message - ERROR")
        error.text()
    });


}




export {predict, getPredictionsNumber, sendMessage};
